import React from "react";
import * as ds from "ui/assets/3ds";

const WhySection = () => {
  return (
    <div className="container my-3 my-md-5">
      <div className="title text-center mb-3 mb-md-5">
        <span className="overlay">Mengapa</span> harus memilih Envio?
      </div>

      <div className="row justify-content-center my-4">
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card-wrapper text-center text-md-start h-100">
            <img src={ds?.com} alt="" className="" />

            <div className="card-title mb-3">Komunikasi</div>
            <div className="card-content">
              Envio berkomitmen menjaga komunikasi yang transparan dan proaktif
              membantu membangun kepercayaan dan meningkatkan kepuasan client
              kami.
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card-wrapper text-center text-md-start h-100">
            <img src={ds?.sys} alt="" />

            <div className="card-title mb-3">Sistem Memadai</div>
            <div className="card-content">
              Sistem kami mampu meningkatkan efisiensi dan efektivitas, serta
              memberikan layanan yang lebih baik kepada client kami.
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 ">
          <div className="card-wrapper text-center text-md-start h-100">
            <img src={ds?.eff} alt="" />

            <div className="card-title mb-3">Efisien</div>
            <div className="card-content">
              Bukan hanya mengirimkan barang, kami juga bisa membantu memberikan
              solusi pengiriman yang disesuaikan dengan kebutuhan masing-masing
              bisnis.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySection;
