import React from "react";

const PrivacyScreen = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="container py-5 px-1 px-md-4">
      <div>
        <div className="text-center fw-semibold fs-4">Kebijakan Privasi</div>
        <div className="p-2">
          <p
            className="fs-7 mb-1 "
            style={{ textIndent: 10, textAlign: "justify" }}
          >
            Kami di PT Envio Global Persada dan afiliasinya ("Envio," "kami,"
            atau "kami") menghargai dan melindungi privasi pengguna Aplikasi
            Envio. Kebijakan Privasi ini menjelaskan bagaimana kami
            mengumpulkan, menggunakan, mengungkapkan, dan melindungi informasi
            pribadi pengguna. Harap membaca Kebijakan Privasi ini dengan seksama
            sebelum menggunakan Aplikasi Envio.
          </p>

          <div>
            <ol type="1" className="ps-3">
              <li className="fw-bold">Informasi yang Kami Kumpulkan</li>
              <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                Kami mengumpulkan informasi dari pengguna Aplikasi Envio dengan
                berbagai cara, termasuk:
              </p>
              <ol type="a" className="ps-3">
                <li className="fw-bold">Informasi Pribadi</li>
                <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                  Kami mengumpulkan informasi pribadi seperti nama, alamat surat
                  elektronik (email), nomor telepon seluler, alamat fisik, dan
                  gambar profil. Informasi ini digunakan untuk mengidentifikasi
                  pengguna, memproses layanan, dan memberikan pengalaman
                  pengguna yang lebih personal.
                </p>

                <li className="fw-bold">Informasi Log</li>
                <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                  Kami mengumpulkan informasi log saat pengguna menggunakan
                  Aplikasi, termasuk alamat IP, tipe perangkat, browser yang
                  digunakan, halaman yang dikunjungi, dan waktu akses. Informasi
                  log digunakan untuk analisis penggunaan Aplikasi dan pemahaman
                  tren penggunaan.
                </p>
              </ol>

              <li className="fw-bold">Penggunaan Informasi</li>
              <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                Kami menggunakan informasi pengguna untuk berbagai tujuan,
                termasuk:
              </p>
              <ol type="a" className="ps-3">
                <li className="fw-bold">Pengiriman Layanan</li>
                <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                  Informasi pribadi pengguna digunakan untuk memproses
                  permintaan layanan yang diajukan melalui Aplikasi Envio,
                  termasuk pengiriman informasi pengiriman kepada pengemudi.
                </p>

                <li className="fw-bold">Keamanan dan Proteksi</li>
                <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                  Data pengguna digunakan untuk menjaga keamanan Aplikasi Envio
                  dan mencegah penyalahgunaan atau tindakan ilegal.
                </p>

                <li className="fw-bold">Pengembangan dan Pembaruan</li>
                <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                  Data pengguna membantu kami mengembangkan dan memperbarui
                  Aplikasi untuk memenuhi kebutuhan pengguna dan perkembangan
                  teknologi.
                </p>

                <li className="fw-bold">Riset dan Analisis</li>
                <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                  Kami menggunakan data pengguna untuk riset statistik dan
                  pemahaman perilaku pengguna, tren penggunaan, dan preferensi
                  pengguna.
                </p>
              </ol>

              <li className="fw-bold">Keamanan Data</li>
              <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                Kami menjalankan langkah-langkah keamanan untuk melindungi data
                pengguna dari akses, penggunaan, atau pengungkapan yang tidak
                sah. Data disimpan di server yang aman, dan hanya diakses oleh
                personel yang memiliki kewenangan.
              </p>

              <li className="fw-bold">Kebijakan Privasi</li>
              <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                Dengan menggunakan Aplikasi Envio, pengguna secara tegas
                menyatakan persetujuan mereka terhadap Kebijakan Privasi ini.
              </p>

              <li className="fw-bold">Perubahan Kebijakan Privasi</li>
              <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                Envio berhak untuk mengubah Kebijakan Privasi ini sesuai dengan
                perubahan dalam hukum atau praktik bisnis kami. Perubahan
                tersebut akan diumumkan melalui Aplikasi atau situs web kami,
                sehingga pengguna selalu mendapatkan informasi terbaru tentang
                pengelolaan data pengguna.
              </p>

              <li className="fw-bold">Hubungi Kami</li>
              <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                Jika Anda memiliki pertanyaan atau kekhawatiran tentang
                Kebijakan Privasi ini, atau jika Anda ingin mengajukan
                permintaan terkait data pengguna, silakan hubungi kami melalui
                alamat email support@envio.co.id atau alamat fisik perusahaan
                yang tertera di akhir Kebijakan Privasi ini.
              </p>
            </ol>
          </div>

          <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
            Kami sangat menghargai dan menjaga privasi pengguna kami. Kebijakan
            Privasi ini mencerminkan komitmen kami untuk melindungi informasi
            pribadi pengguna dan menjalankan praktik-praktik terbaik dalam
            pengelolaan data pengguna.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyScreen;
