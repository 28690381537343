import React from "react";
import cn from "classnames";
import { Form } from "../../../components";

const initialState = {
  name: "",
  phone_number: "",
  email: "",
  service: "",
  errors: {},
  isLoading: false,
};

export const TrySection = () => {
  const [{ name, phone_number, email, service, errors, isLoading }, setState] =
    React.useState(initialState);

  const $onSubmit = async (e) => {
    setTimeout(function () {
      if (name === "") {
        setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, name: "name is required" },
        }));
      }

      if (email === "") {
        setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, email: "email is required" },
        }));
      }

      if (phone_number === "") {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            phone_number: "phone number is required",
          },
        }));
      }

      if (service === "") {
        setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, service: "service is required" },
        }));
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 1000);

    setTimeout(function () {
      setState((prevState) => ({
        ...prevState,
        errors: {},
      }));
    }, 10001);
    if (name && email && phone_number && service) {
      window.location.href = `mailto:support@envio.co.id?body=email: ${email}, name: ${name}, phone number: ${phone_number}, service: ${service}`;
    }
  };

  return (
    <section className="bg-light">
      <div className="container py-3 py-md-5">
        <div className="row align-items-center ">
          <div className="col-md-6">
            <div className="text-start">
              <h1 className="fs-2 text-dark fw-bold">
                Beralihlah ke Era Logistik Digital Sekarang Juga
              </h1>
            </div>
            <div>
              <div className="fs-6 text-dark fw-light mt-4">
                Envio adalah sebuah solusi logistik &amp; supply chain
                management untuk pelaku bisnis di berbagai industri. Didukung
                oleh teknologi yang dapat memantau seluruh pengiriman dan
                pengelolaan produk secara real time.
              </div>
              <div className="fs-6 text-dark fw-light mt-4">
                Envio memberikan layanan end-to-end dari pengelolaan gudang
                dengan kapasitas instan hingga pengelolaan distribusi &amp;
                pengiriman.
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-3 mt-md-0">
            <div className="bg-white rounded-3 px-md-5 py-md-4 p-3">
              <div className="text-center">
                <h1 className="fs-3 text-dark fw-bold">
                  Get in touch with Envio
                </h1>
              </div>
              <div>
                <Form.Input
                  label="Nama"
                  value={name}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      name: e?.target?.value,
                    }))
                  }
                  error={errors?.name}
                />
                <Form.Input
                  label="Nomor Telepon"
                  type="phone"
                  value={phone_number}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      phone_number: e?.target?.value,
                    }))
                  }
                  error={errors?.phone_number}
                />
                <Form.Input
                  label="Email"
                  value={email}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      email: e?.target?.value,
                    }))
                  }
                  error={errors?.email}
                />
                <Form.Input
                  label="Service"
                  value={service}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      service: e?.target?.value,
                    }))
                  }
                  error={errors?.service}
                />
              </div>
              <div>
                <button
                  className={cn(
                    "btn btn-primary w-100 rounded-3 fs-6 py-3 mt-4",
                    isLoading ? "btn-loading" : ""
                  )}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      isLoading: true,
                    }));
                    $onSubmit();
                  }}
                  disabled={isLoading}
                >
                  Get in touch
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
