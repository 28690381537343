import React from "react";
import MainBar from "./mainBar";
import Header from "./header";
import Body from "./body";

const Page = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

Page.MainBar = MainBar;
Page.Header = Header;
Page.Body = Body;

export default Page;
