import React from "react";

const TermsScreen = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="container py-5 px-1 px-md-4">
      <div>
        <div className="text-center fw-semibold fs-4">
          Syarat &amp; Ketentuan
        </div>
        <div className="p-2">
          <p
            className="fs-7 mb-1 "
            style={{ textIndent: 10, textAlign: "justify" }}
          >
            Mohon untuk membaca halaman ini dengan seksama. Di dalamnya terdapat
            Syarat dan Ketentuan yang mengatur akses anda ke dan dalam
            penggunaan Perangkat Lunak Envio dan Layanan yang diberikan oleh
            Envio.
          </p>
          <p
            className="fs-7 mb-1"
            style={{ textIndent: 10, textAlign: "justify" }}
          >
            Apabila anda menyetujui Syarat dan Ketentuan ini atau anda tidak
            memenuhi atau mematuhi ketentuan-ketentuan di dalamnya, anda tidak
            diperbolehkan untuk menggunakan Envio dan/atau Layanan. Dengan
            menggunakan Layanan dari Envio, anda dengan ini menyatakan
            persetujuan anda kepada Syarat dan Ketentuan ini.
          </p>
          <p
            className="fs-7 mb-1"
            style={{ textIndent: 10, textAlign: "justify" }}
          >
            Syarat dan Ketentuan ini berlaku sejak Maret 2022. Apabila anda
            memiliki pertanyaan, silahkan hubungi kami di{" "}
            <b>support@envio.co.id</b>
          </p>

          <div>
            <ol type="A" className="ps-3">
              <li>Hal - hal Umum</li>
              <ul className="ps-3">
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Envio mengacu pada PT Envio Global Persada atau afiliasinya.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Envio Driver mengacu pada pengemudi yang mengajukan dan
                    diterima menjadi pengemudi armada Envio berdasarkan syarat
                    yang telah ditentukan oleh Envio.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Aplikasi ini merupakan perangkat lunak yang berfungsi
                    sebagai sarana dan layanan bagi driver untuk mengirimkan
                    update pengiriman kepada operator.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Aplikasi ini memungkinkan Anda untuk mengirimkan permintaan
                    untuk suatu Layanan kepada Penyedia Layanan. Penerima GPS -
                    yang harus dipasang pada perangkat bergerak (smart phone)
                    dimana Anda telah mengunduh Aplikasi - mendeteksi lokasi
                    Anda dan mengirimkan informasi lokasi Anda ke Penyedia
                    Layanan terkait.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Kami akan melakukan semua upaya wajar dalam menghubungkan
                    Anda dengan Penyedia Layanan untuk mendapatkan Layanan.
                  </p>
                </li>
              </ul>
              <li>Ketentuan untuk Menggunakan Aplikasi</li>
              <ul className="ps-3">
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Anda menyatakan dan menjamin bahwa Anda adalah individu yang
                    secara hukum berhak untuk mengadakan perjanjian yang
                    mengikat berdasarkan hukum Negara Republik Indonesia,
                    khususnya Ketentuan Penggunaan, untuk menggunakan Aplikasi
                    dan bahwa Anda telah berusia minimal 21 tahun atau sudah
                    menikah dan tidak berada di bawah perwalian.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Kami mengumpulkan dan memproses informasi pribadi Anda,
                    seperti nama, alamat surat elektronik (surel / e-mail), dan
                    nomor telepon seluler Anda ketika Anda mendaftar. Anda harus
                    memberikan informasi yang akurat dan lengkap, memperbaharui
                    informasi dan setuju untuk memberikan kepada kami bukti
                    identitas apapun yang secara wajar dapat kami mintakan.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Anda hanya dapat menggunakan Aplikasi ketika Anda telah
                    mendaftar pada Aplikasi tersebut. Setelah Anda berhasil
                    terdaftar, maka Aplikasi akan memberikan Anda suatu akun
                    pribadi yang dapat diakses dengan kata sandi yang Anda
                    pilih. Dan hanya anda yang menggunakan akun tersebut untuk
                    mengakses layanan.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Anda tidak diperkenankan untuk mengubah, memodifikasi atau
                    membahayakan, Aplikasi dan/atau Sistem atau mencoba untuk
                    membahayakan, mengubah atau memodifikasi Aplikasi dan/atau
                    Sistem dengan cara apapun.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Anda akan menjaga kerahasiaan dan tidak akan menyalahgunakan
                    informasi yang Anda terima dari penggunaan Aplikasi
                    tersebut.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Anda memahami dan setuju bahwa penggunaan Aplikasi oleh Anda
                    akan tunduk pula pada Kebijakan Privasi kami sebagaimana
                    dapat berubah dari waktu ke waktu. Dengan menggunakan
                    Aplikasi, Anda juga memberikan persetujuan sebagaimana
                    dipersyaratkan berdasarkan Kebijakan Privasi kami.
                  </p>
                </li>
              </ul>
              <li>Hak Kekayaan Intelektual</li>
              <ul className="ps-3">
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Aplikasi, Situs Web, Perangkat Lunak, dan segala hak,
                    kepemilikan, dan kepentingan di dalam dan yang terkait,
                    merupakan property milik Envio, pihak yang melisensi, dan
                    dilindungi oleh Hukum Hak Kekayaan Intelektual. Kecuali,
                    apabila dapat diaplikasikan, lisensi terbatas diberikan
                    secara nyata kepada anda di dalam Syarat dan Ketentuan,
                    Envio berhak untuk dirinya dan para pihak yang melisensi,
                    seluruh hak, kepemilikan, dan kepentingan lainnya.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Anda tidak diperbolehkan untuk memproduksi, menyalin,
                    memodifikasi, menampilkan, menjual atau mendistribusikan
                    Perangkat Lunak, atau konten yang ditampilkan di dalam
                    Aplikasi/Perangkat Lunak, atau menggunakannya dengan cara
                    lainnya untuk konsumsi publik atau maksud komersial, tanpa
                    persetujuan Envio, dan/atau pihak ketiga terkait.
                    Pelanggaran atas ketentuan ini akan secara otomatis
                    menghapus izin anda untuk menggunakan Aplikasi/Perangkat
                    Lunak atau akses kepada Layanan, sebagaimana demikian anda
                    harus dengan segera menghancurkan segala dan seluruh salinan
                    yang anda buat atas Perangkat Lunak dan segala konten yang
                    ditampilkan di dalamnya.
                  </p>
                </li>
              </ul>
              <li>Pernyataan Penyangkalan</li>
              <ul className="ps-3">
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Envio dan Layanan, termasuk namun tidak terbatas pada
                    Layanan, Perangkat Lunak, dan layanan pelanggan, disediakan
                    untuk Pengguna apa adanya.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Envio tidak memberikan garansi, jaminan, atau janji bahwa
                    Layanan, Perangkat Lunak, atau layanan pelanggan akan
                    bekerja sebagaimana ditujukan pada telepon, komputer,
                    tablet, atau alat/device lainnya milik Pengguna.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Envio tidak memberikan jaminan bahwa ketersediaan,
                    reliabilitas, waktu, keakuratan, atau kualitas Layanan,
                    Perangkat Lunak, atau Pengemudi.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Envio tidak bertanggungjawab atas setiap kehilangan atau
                    kerugian, termasuk namun tidak terbatas kepada, cedera
                    Pengguna atau orang lain yang mungkin derita, segala
                    kerusakan terhadap properti, termasuk Muatan, yang dimiliki
                    Pengguna atau pihak lainnya, atau segala Kehilangan dan
                    Kerusakan Tidak Langsung, yang diakibatkan oleh Layanan,
                    dalam hal terkait Pengendara, atau proses pengiriman Muatan.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Envio berhak untuk menghentikan atau menghapus segala
                    hubungan dengan Pengguna atau akun Pengguna, yang dibuat
                    untuk menggunakan Layanan atau Perangkat Lunak, apabila
                    Envio yakin bahwa Pengguna melanggar ketentuan manapun dari
                    Syarat dan Ketentuan ini dan segala kebijakan lainnya yang
                    terkait.
                  </p>
                </li>
              </ul>
              <li>Ketentuan Lainnya</li>
              <ul className="ps-3">
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Envio dapat mengubah Syarat dan Ketentuan ini dari waktu ke
                    waktu dan berhak untuk melakukannya tanpa memberikan
                    pemberitahuan terlebih dahulu. Segala perubahan Syarat dan
                    Ketentuan akan ditampilkan di Perangkat Lunak Envio.
                  </p>
                </li>
                <li>
                  <p className="fs-7 mb-1" style={{ textAlign: "justify" }}>
                    Anda menerima seluruhnya dan tanpa syarat bentuk Syarat dan
                    Ketentuan terkini sebagaimana direpresentasikan oleh Envio
                    pada setiap digunakannya Layanan.
                  </p>
                </li>
              </ul>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsScreen;
