/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Accordion } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router";

import { useModal } from "../../hooks";
import MenuRoute from "./menu.route";

const MenuSm = () => {
  const History = useNavigate();
  const Modal = useModal();

  return (
    <div className="d-block d-md-none">
      <FaBars size={22} onClick={() => Modal.show()} />

      <Modal.Render fullscreen title="Menu" className="text-center bg-dark">
        <ul className="navbar-nav pt-lg-3">
          {MenuRoute.map((menu, index) => {
            return !menu?.isDropdown ? (
              <li key={index} className="nav-item border-b-1">
                <a
                  className="nav-link"
                  onClick={() => {
                    Modal.hide();
                    History(menu?.path);
                  }}
                >
                  <span className="nav-link-title">{menu?.title}</span>
                </a>
              </li>
            ) : (
              <li key={index} className="nav-item border-b-1">
                <Accordion>
                  <Accordion.Item eventKey={menu?.title}>
                    <Accordion.Header className="b ">
                      <span className="nav-link-title">{menu?.title}</span>
                    </Accordion.Header>
                    <Accordion.Body
                      className="py-0"
                      style={{
                        paddingLeft: "1.5rem",
                        paddingRight: 0,
                      }}
                    >
                      <ul
                        className="nav-vertical nav border-start"
                        style={{ paddingLeft: "1rem" }}
                      >
                        {menu?.child.map((c, index) => {
                          return (
                            <li className="nav-item border-b-1" key={index}>
                              <a
                                onClick={() => {
                                  Modal.hide();
                                  History(c?.path);
                                }}
                                className="nav-link"
                              >
                                {c?.title}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
            );
          })}

          <li className="nav-item border-b-1">
            <span
              className="nav-link cursor-pointer"
              onClick={() =>
                window.open("https://global.envio.asia/", "_blank")
              }
            >
              Tracking
            </span>
          </li>
          {/* <li className="nav-item border-b-1">
            <span
              className="nav-link cursor-pointer"
              onClick={() =>
                window.open("https://operator.envio.co.id/", "_blank")
              }
            >
              Operator
            </span>
          </li> */}
        </ul>
      </Modal.Render>
    </div>
  );
};

export default MenuSm;
