import React from "react";
import { motion } from "framer-motion";

import { BerkembangSection } from "../about/section";
import { WholesaleSection } from "./section";

const ServiceScreen = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="container">
        <div className=" pt-3 pt-md-5">
          <div className="text-start">
            <h1 className="fs-2 text-primary fw-bold">Layanan Kami</h1>
          </div>
        </div>
      </div>
      <WholesaleSection />
      <BerkembangSection />
    </motion.div>
  );
};

export default ServiceScreen;
