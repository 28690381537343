/* eslint-disable no-undef */
// import * as DefaultConfig from "../config.local.json";

/**
 * Define default config
 */
export const $config = {
  API_URL: "",
  APP_VERSION: `envio/@v2`,
  APP_DEPRECATED: false,
};

/**
 * Get defined config
 */
export function getConfig() {
  return $config;
}

/**
 * Setup config from remote on production
 */
export function setConfig() {
  if (process.env.NODE_ENV === "development") {
    console.groupCollapsed("APP CONFIG");
    console.table($config);
    console.groupEnd();
  }

  return $config;
}
