import React from "react";
import { Route, useLocation, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// Read subrouter in each subfolder
let routes = [];
const rf = require.context("./ui/screens/", true, /^.*_subrouter.js$/);
rf.keys().forEach((fileName) => {
  routes = routes.concat(rf(fileName).default);
});

const AnimatedRouter = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        {routes.map((route, i) => (
          <Route
            key={i}
            exact
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRouter;
