import React from "react";
import satu from "../../../assets/transparansi.png";
import dua from "../../../assets/visibilitas.png";
import tiga from "../../../assets/efisiensi.png";

export const WhySection = () => {
  return (
    <section>
      <div className="container py-3 py-md-5">
        <div className="d-flex align-items-center justify-content-center">
          <div className="col-md-8 text-center">
            <h1 className="fs-2 text-dark fw-bold">
              Mengapa Memilih Menggunakan Jasa Envio Logistics?
            </h1>
          </div>
        </div>

        <div className="d-md-flex align-items-center justify-content-center gap-5 mt-3 mt-md-5">
          <div className="why-envio mb-3 mb-md-0">
            <img src={satu} alt="" className="img-fluid" />
            <div className="description text-white">
              <div>
                <h2>Tranparansi</h2>
                <div>
                  Pelaporan, pemantauan, dan pembuatan invoice yang transparan.
                </div>
              </div>
            </div>
          </div>
          <div className="why-envio mb-3 mb-md-0">
            <img src={dua} alt="" className="img-fluid" />
            <div className="description text-white">
              <div>
                <h2>Visibilitas</h2>
                <div>
                  Pelaporan secara real-time pada data produk, dan semua detail
                  pergerakan.
                </div>
              </div>
            </div>
          </div>
          <div className="why-envio mb-3 mb-md-0">
            <img src={tiga} alt="" className="img-fluid" />
            <div className="description text-white">
              <div>
                <h2>Efisiensi</h2>
              </div>
              <div>
                Dengan sistem yang terintegrasi, ruang waktu, rute, jarak dan
                biaya dapat dilakukan secara optimal.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
