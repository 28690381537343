import React from "react";
import hero from "ui/assets/hero-full.png";

const Hero = ({ priceRef }) => {
  const scrollTo = () => {
    priceRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div>
      <div className="hero-img">
        <img src={hero} alt="" />

        <div className="d-flex d-md-none">
          <div
            className="position-absolute bottom-0 text-white text-center mb-3 px-3 "
            style={{ fontWeight: 700 }}
          >
            Partner logistik ideal untuk para pengusaha dengan servis dan
            layanan flexible yang mampu menjangkau banyak daerah untuk
            kelancaran pengiriman Bisnis mu
          </div>
        </div>

        <div className="caption d-none d-md-flex">
          <div className="text-caption text-white mb-4 w-50">
            Partner logistik ideal untuk para pengusaha dengan servis dan
            layanan flexible yang mampu menjangkau banyak daerah untuk
            kelancaran pengiriman Bisnis mu
          </div>
          <div className="button-action">
            <button
              className="btn btn-primary"
              onClick={() => scrollTo()}
              style={{ fontWeight: 700 }}
            >
              Lihat Penawaran
            </button>
            <button
              className="btn btn-outline-primary border-primary bg-white"
              onClick={() =>
                window.open("https://wa.me/6281213558273", "_blank")
              }
              style={{ fontWeight: 700 }}
            >
              Hubungi Tim Kami
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex d-md-none flex-column p-3 gap-3 ">
        <button
          className="btn btn-primary w-100"
          onClick={() => scrollTo()}
          style={{ fontWeight: 700 }}
        >
          Lihat Penawaran
        </button>
        <button
          className="btn btn-outline-primary border-primary bg-white w-100"
          onClick={() => window.open("https://wa.me/6281213558273", "_blank")}
          style={{ fontWeight: 700 }}
        >
          Hubungi Tim Kami
        </button>
      </div>
    </div>
  );
};

export default Hero;
