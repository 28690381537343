import React from "react";
import estimate from "../../../assets/estimate.png";
import start from "../../../assets/start.png";

export const BerkembangSection = () => {
  return (
    <section>
      <div className="container">
        <div className="py-3 py-md-5">
          <div className="text-center">
            <h1 className="fs-2 text-dark fw-bold">
              <span className="text-primary">Berkembang</span> Bersama Envio
            </h1>
          </div>

          <div className="d-md-flex gap-4 align-items-center justify-content-center mt-3 mt-md-5">
            <div className="text-center mb-3 mb-md-0 ">
              <div className="position-relative ">
                <img src={estimate} alt="" className="img-fluid" />
                <div
                  className="position-absolute  start-50 translate-middle-x w-100"
                  style={{ bottom: 30 }}
                >
                  <div className="fs-5 fw-bold d-inline-block">
                    Estimasikan Pengiriman mu
                  </div>
                </div>
              </div>
              {/* <div className="btn btn-primary rounded-pill mt-2 mt-md-4 fs-6 px-4">
                Mulai Estimasi
              </div> */}
            </div>

            <div className="text-center mb-3 mb-md-0 ">
              <div className="position-relative ">
                <img src={start} alt="" className="img-fluid" />
                <div
                  className="position-absolute  start-50 translate-middle-x w-100"
                  style={{ bottom: 30 }}
                >
                  <div className="fs-5 fw-bold d-inline-block">
                    Mulai Proses Perjalanan mu
                  </div>
                </div>
              </div>
              {/* <div className="btn btn-primary rounded-pill mt-2 mt-md-4 fs-6 px-4">
                Mulai Daftar
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
