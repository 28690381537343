import * as Action from "./action";

const defineInitialState = () => ({
  isReady: false,
  isKeyboardVisible: false,
  statusBar: {
    backgroundColor: "transparent",
    barStyle: "dark-content",
    isHidden: true,
  },
});

export function reducer(state = defineInitialState(), action) {
  switch (action.type) {
    case Action.$appReady.ACTION:
      return {
        ...state,
        statusBar: {
          ...state.statusBar,
          isHidden: false,
        },
        isReady: true,
        referralCode: action.ref_code,
      };
    case Action.$keyboardVisible.ACTION:
      return {
        ...state,
        isKeyboardVisible: action.keyboardVisible,
      };
    case Action.$statusBar.ACTION:
      return {
        ...state,
        statusBar: action.statusBar,
      };
    default:
      return state;
  }
}

export function persister({
  isKeyboardVisible,
}) {
  return {
    isKeyboardVisible,
  };
}
