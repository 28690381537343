/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import MenuRoute from "./menu.route";

const MenuConsended = ({ fluid }) => {
  const location = useLocation();
  const splitLocation = location.pathname.split("/");

  return (
    <div className="navbar-collapse collapse" id="navbar-menu">
      <div className="container pe-0">
        <ul className="navbar-nav d-flex gap-4 justify-content-end">
          {MenuRoute.map((menu, index) => {
            return (
              <li key={index} className="nav-item">
                <NavLink
                  to={menu.path}
                  className={cn(
                    "nav-link",
                    splitLocation[1] === menu.isActive ? "active" : ""
                  )}
                >
                  <span className="nav-link-title">{menu?.title}</span>
                </NavLink>
              </li>
            );
          })}

          <li className="nav-item">
            <span
              className="nav-link-title cursor-pointer"
              onClick={() =>
                window.open("https://global.envio.co.id/", "_blank")
              }
            >
              Tracking
            </span>
          </li>
          {/* <li className="nav-item ms-2">
            <span
              className="nav-link-title cursor-pointer"
              onClick={() =>
                window.open("https://operator.envio.co.id/", "_blank")
              }
            >
              Operator
            </span>
          </li> */}

          {/* <li className="nav-item">
            <button className="nav-link btn btn-outline-primary border-primary mt-1 mb-1 px-4">
              Sign In
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default MenuConsended;
