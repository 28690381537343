import DeeplinkScreen from ".";
import DriverDeeplinkScreen from "./driver";

const routes = [
  {
    path: "/transporter",
    component: DeeplinkScreen,
  },
  {
    path: "/driver",
    component: DriverDeeplinkScreen,
  },
];

export default routes;
