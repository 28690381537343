const MenuRoute = [
  {
    title: "Beranda",
    path: "/",
    isActive: "",
  },
  {
    title: "Tentang Kami",
    path: "/about",
    isActive: "about",
  },
  {
    title: "Layanan Kami",
    path: "/services",
    isActive: "services",
  },
];

export default MenuRoute;
