import React from "react";
import { motion } from "framer-motion";

import { AboutSection, BerkembangSection, VisiSection } from "./section";

const AboutScreen = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <AboutSection />
      <VisiSection />
      <BerkembangSection />
    </motion.div>
  );
};

export default AboutScreen;
