import React from "react";
import ws from "../../../assets/wholesale-service.png";

export const ServiceWholesale = () => {
  return (
    <section className="bg-light">
      <div className="container">
        <div className="row align-items-center py-3 py-md-5">
          <div className="col-md-6 mb-3 mb-md-0 mt-3 mt-md-0 order-2 order-md-1">
            <h2 className="fs-3 fw-bold">Wholesale Distribution</h2>
            <div className="fs-5 text-dark fw-light mt-3 mt-md-4">
              Penanganan Distribusi Standar untuk produk General Cargo atau
              Makanan Kering melibatkan pemindahan produk dalam jumlah besar
              dari gudang ke gudang atau pelanggan b2b biasanya dalam bentuk
              penuh (FTL) atau kurang dari muatan truk (LTL).
            </div>
          </div>
          <div className="col-md-6 text-end order-1 order-md-2">
            <img
              src={ws}
              alt=""
              className="img-fluid"
              style={{ maxHeight: 400 }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
