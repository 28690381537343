import React from "react";
import truck from "../../../assets/visi.png";

export const VisiSection = () => {
  return (
    <section>
      <div className="container">
        <div className="py-3 py-md-5">
          <div className="text-start">
            <h1 className="fs-2 text-dark fw-bold">Visi dan Misi</h1>
          </div>

          <div className="row align-items-center mt-3 mt-md-5">
            <div className="col-md-6 mb-3 mb-md-0">
              <img
                src={truck}
                alt=""
                className="img-fluid"
                style={{ maxHeight: 385 }}
              />
            </div>
            <div className="col-md-6">
              <div className="fs-5 text-dark fw-light ">
                Envio dapat menyesuaikan jasa dengan kebutuhan sebuah perusahaan
                (customize service) dimana perusahaan dapat memilih apa saja yg
                mau digunakan pada servis envio. Saat ini Envio memiliki 7328
                truk, 40 gudang dan 713 kurir yang selalu sedia untuk memenuhi
                kebutuhan perusahaan Anda.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
