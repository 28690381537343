import React from "react";
import {
  HeroSection,
  WhySection,
  PriceSection,
  SocialSection,
} from "./section";

const DistributionScreen = () => {
  const price = React.useRef(null);

  return (
    <div>
      <HeroSection priceRef={price} />
      <WhySection />
      <PriceSection priceRef={price} />
      <SocialSection />
    </div>
  );
};

export default DistributionScreen;
