/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import undraw from "../../assets/undraw.png";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DeeplinkScreen = () => {
  let query = useQuery();
  const History = useNavigate();

  React.useEffect(() => {
    if (query.get("ref_code")) {
      let ref = query.get("ref_code");
      window.location = `intent://boarding?ref_code=${ref}#Intent;scheme=envio-transporter;package=com.envio.transporter;end`;
    } else {
      History("/");
    }
  }, []);

  return (
    <div
      className="container py-5 px-1 px-md-4"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className=" py-3 px-3 "
        style={{
          width: 400,
          border: "1px solid #ddd",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={undraw}
          alt=""
          style={{ maxHeight: 200 }}
          className="img-fluid center mb-4"
        />

        <p className="fs-4 mb-1 fw-bold text-center">Hang Tight !</p>
        <p className="fs-6 mb-1 text-center ">
          You're being redirected to another page, it may takes upto 10 seconds
        </p>
      </div>
    </div>
  );
};

export default DeeplinkScreen;
