import React from "react";
import { linked, insta, tiktok } from "ui/assets/graph";

const SocialSection = () => {
  return (
    <div className="container my-5">
      <div className="title text-center mb-4">
        <span className="overlay social">Temui</span> kami di
      </div>

      <div className="d-flex justify-content-center align-items-center gap-4">
        <img
          src={linked}
          alt=""
          style={{ height: 60 }}
          className="cursor-pointer"
          onClick={() =>
            window.open("https://www.linkedin.com/company/envio-id", "_blank")
          }
        />
        <img
          src={insta}
          alt=""
          style={{ height: 60 }}
          className="cursor-pointer"
          onClick={() =>
            window.open("https://www.instagram.com/envio.asia", "_blank")
          }
        />
        <img
          src={tiktok}
          alt=""
          style={{ height: 60 }}
          className="cursor-pointer"
          onClick={() =>
            window.open("https://www.tiktok.com/@envio.logistics", "_blank")
          }
        />
      </div>
    </div>
  );
};
export default SocialSection;
