import React from "react";
import stat from "../../../assets/stat.png";

export const StatsSection = () => {
  return (
    <section>
      <div className="container py-3 py-md-5">
        <div className="d-flex align-items-center justify-content-center">
          <div className="col-md-8 text-center">
            <h1 className="fs-2 text-dark fw-bold">
              Memberdayakan Perdagangan Global &amp; Domestik melalui Logistik
              Digital
            </h1>
          </div>
        </div>

        <div className="d-md-flex mt-3 mt-md-5 px-md-4 px-3 justify-content-center align-items-start">
          <div className="col-md-3 col-12 text-md-end text-center pe-1 pt-2">
            <div className="mb-4 fs-5 ">
              “Sistem yang terintegrasi secara end to end”
            </div>
            <div className="mb-4 fs-5 ">
              Didirikan untuk menjadi solusi end to end logistik
            </div>
            <div className="mb-4 fs-5">
              Envio difasilitasi dengan 3 sistem untuk mengelola logistik secara
              end to end (TMS,WMS,OMS)
            </div>
          </div>
          <div className="col-md-6 col-12 text-center">
            <img
              src={stat}
              alt=""
              className="img-fluid"
              style={{ maxHeight: 500 }}
            />
          </div>
          <div className="col-md-3 col-12 text-md-start text-center ps-1 pt-2">
            <div className="mb-4 ">
              <div className="fs-2 fw-medium">50rb+</div>
              <div className="fs-5 ">
                Pesanan terpenuhi dengan menggunakan jasa Envio Logistics
              </div>
            </div>
            <div className="mb-4 ">
              <div className="fs-2 fw-medium">172+</div>
              <div className="fs-5 ">
                Jumlah kurir pengantaran untuk <i>Last Mile</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
