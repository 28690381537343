/* eslint-disable no-undef */
/* eslint-disable max-len */
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AnimatedRouter from "./animatedRouter";
import { Layout } from "./ui/components";
import ScrollToTop from "./ui/hooks/scrolltotop";

const Routers = () => {
  return (
    <Router>
      <Layout>
        <Layout.Header sticky consended />
        <Layout.Body>
          <ScrollToTop />
          <AnimatedRouter />
        </Layout.Body>
        <Layout.Footer />
      </Layout>
    </Router>
  );
};

export default Routers;
