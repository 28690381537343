import React from "react";
import FormInput from "./form.input";
import FormTextbox from "./form.textbox";
// import FormInputMasked from "./form.mask";

import type { FormEvents } from "../../../utilities/events";

export type Props = {|
  ...FormEvents,
  +children?: React.Node,
  +className?: string,
  +action?: string,
  +method?: string,
  +autoComplete: "on" | "off",
|};

function Form({
  className,
  children,
  action,
  method,
  onSubmit,
  autoComplete,
}: Props): React.Node {
  return (
    <form
      className={className}
      onSubmit={(e) => {
        onSubmit(e);
        e.preventDefault();
      }}
      action={action}
      method={method}
      autoComplete={autoComplete}
    >
      {children}
    </form>
  );
}

Form.defaultProps = {
  autoComplete: "off",
  method: "POST",
};

// Form.Input = FormInputMasked;
Form.Input = FormInput;
Form.Textbox = FormTextbox;

export default Form;
