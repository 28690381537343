/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Header = ({ pretitle, title, children }) => {
  return (
    <div className="page-header d-print-none m-0">
      <div className="row justify-content-between align-items-center">
        <div className="col-auto">
          {pretitle ? <div className="page-pretitle">{pretitle}</div> : null}
          {title ? <h2 className="page-title">{title}</h2> : null}
        </div>
        {children ? <div className="col-auto c-xs-3">{children}</div> : null}
      </div>
    </div>
  );
};

export default Header;
