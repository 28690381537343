import React from "react";
import { motion } from "framer-motion";

import {
  ReportSection,
  FullscreenVideo,
  IntegrationSection,
  SolutionSection,
  StatsSection,
  TrySection,
  WhySection,
} from "./section";

const HomeScreen = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <FullscreenVideo />
      <WhySection />
      <SolutionSection />
      <StatsSection />
      <IntegrationSection />
      <TrySection />
      <ReportSection />
    </motion.div>
  );
};

export default HomeScreen;
