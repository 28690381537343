import React from "react";
import ReactDOM from "react-dom/client";

import { Provider as StateProvider } from "react-redux";

import AOS from "aos";

import * as serviceWorker from "./serviceWorker";
import { getStore, setStore } from "./services/store";
import { setConfig } from "./utilities/config";
import App from "./app";
import bootstrap from "./boot";

import "aos/dist/aos.css";
import "./ui/themes/styles.scss";

setConfig();
setStore();
const root = ReactDOM.createRoot(document.getElementById("root"));

const AppContainer = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <StateProvider store={getStore()}>
      <App />
    </StateProvider>
  );
};

root.render(<AppContainer />);

bootstrap();
serviceWorker.unregister();
