/* eslint-disable no-undef */
import * as ActionCreator from "../../utilities/action.creator";

const MODULE = "App";

export const $appReady = ActionCreator.Default(MODULE, "Ready", () => {
  return async (dispatch) => {
    await Promise.all([new Promise((resolve) => setTimeout(resolve, 1000))]);
    return dispatch($appReady.action());
  };
});

export const $keyboardVisible = ActionCreator.Default(
  MODULE,
  "KeyboardVisible",
  (keyboardVisible) => {
    return async (dispatch) => {
      return dispatch($keyboardVisible.action({ keyboardVisible }));
    };
  }
);

export const $statusBar = ActionCreator.Default(
  MODULE,
  "StatusBar",
  (theme = "dark") => {
    return async (dispatch) => {
      let statusBar = {
        backgroundColor: "transparent",
        barStyle: "dark-content",
        isHidden: false,
      };

      if (theme === "dark") {
        statusBar.backgroundColor = "#aa0000";
        statusBar.barStyle = "dark-content";
      } else if (theme === "transparent-dark") {
        statusBar.backgroundColor = "transparent";
        statusBar.barStyle = "light-content";
      } else if (theme === "hidden") {
        statusBar.isHidden = true;
      }

      return dispatch($statusBar.action({ statusBar }));
    };
  }
);
