import React from "react";
import { motion } from "framer-motion";

import { BerkembangSection } from "../about/section";
import { ServiceWholesale, SkemaSection, TipeSection } from "./section";

const WholesaleScreen = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ServiceWholesale />
      <TipeSection />
      <SkemaSection />
      <BerkembangSection />
    </motion.div>
  );
};

export default WholesaleScreen;
