import React from "react";
import poin from "../../../assets/point.jpg";
import icon1 from "../../../assets/1.png";
import icon2 from "../../../assets/2.png";
import icon3 from "../../../assets/3.png";
import icon4 from "../../../assets/4.png";

export const IntegrationSection = () => {
  return (
    <section className="">
      <div className="container py-3 py-md-5">
        <div className="d-flex align-items-center justify-content-center">
          <div className="col-md-8 text-center">
            <h1 className="fs-2 text-dark fw-bold">
              Pengelolaan Logistik Terintegrasi dari Hulu ke Hilir secara
              Digital.
            </h1>
          </div>
        </div>
        <div className="d-block d-md-none mt-4">
          <div className="row mb-3">
            <div className="col-2">
              <img
                src={icon1}
                alt=""
                className="img-fluid"
                style={{ maxHeight: 50 }}
              />
            </div>
            <div className="col-10">
              <h4 className="fs-5">Kapasitas Gudang Instan</h4>
              <div>
                Memberikan akses kepada kapasitas gudang dengan spesifikasi
                &amp; skala di butuhkan secara instan dan scalable.
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-2">
              <img
                src={icon2}
                alt=""
                className="img-fluid"
                style={{ maxHeight: 50 }}
              />
            </div>
            <div className="col-10">
              <h4 className="fs-5">Pengiriman Rantai Pasokan</h4>
              <div>
                Memberikan akses kepada kapasitas gudang dengan spesifikasi
                &amp; skala di butuhkan secara instan dan scalable.
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-2">
              <img
                src={icon3}
                alt=""
                className="img-fluid"
                style={{ maxHeight: 50 }}
              />
            </div>
            <div className="col-10">
              <h4 className="fs-5">Real Time Tracking</h4>
              <div>
                Memberikan akses tracking terhadap pengiriman secara langsung
                berdasarkan waktu nyata.
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-2">
              <img
                src={icon4}
                alt=""
                className="img-fluid"
                style={{ maxHeight: 50 }}
              />
            </div>
            <div className="col-10">
              <h4 className="fs-5">Laporan &amp; Transaksi</h4>
              <div>
                Laporan lengkap transaksi dan pengiriman dengan mudah dapat di
                akses pada platform Envio.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="position-relative d-none d-md-block overflow-hidden ">
        <img
          src={poin}
          alt=""
          className="img-fluid d-none d-md-block"
          style={{ minHeight: 800 }}
        />

        <div className="integrasi">
          <div className="d-flex mt-5 pt-5">
            <div className="col-md-7">
              <div className="d-flex points ">
                <div className="col-2 text-center">
                  <img
                    src={icon1}
                    alt=""
                    className="img-fluid"
                    style={{ maxHeight: 60 }}
                  />
                </div>
                <div className="col-10">
                  <h4>Kapasitas Gudang Instan</h4>
                  <div className="fs-8">
                    Memberikan akses kepada kapasitas gudang dengan spesifikasi
                    &amp; skala di butuhkan secara instan dan scalable.
                  </div>
                </div>
              </div>

              <div className="d-flex points">
                <div className="col-2 text-center">
                  <img
                    src={icon2}
                    alt=""
                    className="img-fluid"
                    style={{ maxHeight: 60 }}
                  />
                </div>
                <div className="col-10">
                  <h4>Pengiriman Rantai Pasokan</h4>
                  <div className="fs-8">
                    Memberikan akses kepada kapasitas gudang dengan spesifikasi
                    &amp; skala di butuhkan secara instan dan scalable.
                  </div>
                </div>
              </div>
              <div className="d-flex  points">
                <div className="col-2 text-center">
                  <img
                    src={icon3}
                    alt=""
                    className="img-fluid"
                    style={{ maxHeight: 60 }}
                  />
                </div>
                <div className="col-10">
                  <h4>Real Time Tracking</h4>
                  <div className="fs-8">
                    Memberikan akses tracking terhadap pengiriman secara
                    langsung berdasarkan waktu nyata.
                  </div>
                </div>
              </div>
              <div className="d-flex points">
                <div className="col-2 text-center">
                  <img
                    src={icon4}
                    alt=""
                    className="img-fluid"
                    style={{ maxHeight: 60 }}
                  />
                </div>
                <div className="col-10">
                  <h4>Laporan &amp; Transaksi</h4>
                  <div className="fs-8">
                    Laporan lengkap transaksi dan pengiriman dengan mudah dapat
                    di akses pada platform Envio.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
