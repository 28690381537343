import React from "react";
import { useNavigate } from "react-router-dom";

import ws from "../../../assets/wholesale-service.png";
import cc from "../../../assets/cc.png";
import fp from "../../../assets/fp.png";
import ii from "../../../assets/ii.png";

export const WholesaleSection = () => {
  const History = useNavigate();

  return (
    <section>
      <div className="container">
        <div className="row align-items-center py-3 py-md-5">
          <div className="col-md-6 mb-3 mb-md-0 mt-3 mt-md-0 order-2 order-md-1">
            <h2 className="fs-3 fw-medium">Wholesale Distribution</h2>
            <div className="fs-5 text-dark fw-light mt-3 mt-md-4">
              Penanganan Distribusi Standar untuk produk General Cargo atau
              Makanan Kering melibatkan pemindahan produk dalam jumlah besar
              dari gudang ke gudang atau pelanggan b2b biasanya dalam bentuk
              penuh (FTL) atau kurang dari muatan truk (LTL).
            </div>

            <div
              className="btn btn-primary mt-3 mt-md-4 fs-6 px-4"
              onClick={() => History("/services/wholesale")}
            >
              Pelajari Lebih Lanjut
            </div>
          </div>

          <div className="col-md-6 text-end order-1 order-md-2">
            <img
              src={ws}
              alt=""
              className="img-fluid"
              style={{ maxHeight: 400 }}
            />
          </div>
        </div>

        <div className="row align-items-center py-3 py-md-5">
          <div className="col-md-6 text-start">
            <img
              src={cc}
              alt=""
              className="img-fluid"
              style={{ maxHeight: 400 }}
            />
          </div>

          <div className="col-md-6 text-start text-md-end mb-3 mb-md-0 mt-3 mt-md-0">
            <h2 className="fs-3 fw-medium">Cold Chain</h2>
            <div className="fs-5 text-dark fw-light mt-3 mt-md-4">
              Penanganan distribusi lebih hemat dikarenakan Envio memiliki
              ritase minimal 1x dalam sehari dan Envio memiliki gudang yang
              tersebar di beberapa kota di Indonesia untuk menampung pengiriman
              serta mengurus pengantaran ke penerima barang.
            </div>

            {/* <div className="btn btn-primary mt-3 mt-md-4 fs-6 px-4">
              Pelajari Lebih Lanjut
            </div> */}
          </div>
        </div>

        <div className="row align-items-center py-3 py-md-5">
          <div className="col-md-6 mb-3 mb-md-0 mt-3 mt-md-0 order-2 order-md-1">
            <h2 className="fs-3 fw-medium">Fresh Produce</h2>
            <div className="fs-5 text-dark fw-light mt-3 mt-md-4">
              Penanganan distribusi dengan fasilitas temperatur yang terkontrol
              (temperature-controlled), mulai dari proses persiapan pengiriman,
              proses pengemasan barang perishable sampai dengan kedatangan di
              tujuan.
            </div>

            {/* <div className="btn btn-primary mt-3 mt-md-4 fs-6 px-4">
              Pelajari Lebih Lanjut
            </div> */}
          </div>

          <div className="col-md-6 text-end order-1 order-md-2">
            <img
              src={fp}
              alt=""
              className="img-fluid"
              style={{ maxHeight: 400 }}
            />
          </div>
        </div>

        <div className="row align-items-center  py-3 py-md-5">
          <div className="col-md-6 text-start">
            <img
              src={ii}
              alt=""
              className="img-fluid"
              style={{ maxHeight: 400 }}
            />
          </div>

          <div className="col-md-6 text-start text-md-end mb-3 mb-md-0 mt-3 mt-md-0">
            <h2 className="fs-3 fw-medium">Intercity/Intracity</h2>
            <div className="fs-5 text-dark fw-light mt-3 mt-md-4">
              Penanganan distribusi lebih hemat dikarenakan Envio memiliki
              ritase minimal 1x dalam sehari dan Envio memiliki gudang yang
              tersebar di beberapa kota di Indonesia untuk menampung pengiriman
              serta mengurus pengantaran ke penerima barang.
            </div>

            {/* <div className="btn btn-primary mt-3 mt-md-4 fs-6 px-4">
              Pelajari Lebih Lanjut
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};
