/* eslint-disable no-undef */
import { createLogger } from "./logger";
import { EventEmitter } from "./event";
import { getStore } from "../services/store";

const Logger = createLogger("REST");
const qs = require("qs");

class restLibrary {
  events = new EventEmitter();

  constructor() {
    this.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  _jwtHeader() {
    const { getState } = getStore();

    let jwtHeader = {};
    const jwt = getState().Auth.token;

    if (jwt !== "") {
      jwtHeader = {
        Authorization: "Bearer " + jwt,
      };
    }

    return jwtHeader;
  }

  async request(url, method, body, isQuery = false) {
    if (!url) {
      throw new Error("path is undefined");
    }

    if (isQuery && body) {
      url = `${url}?${qs.stringify(body)}`;
    }

    const option = {
      method,
      headers: this.headers,
    };

    if (method !== "GET" && body) {
      Object.assign(option, { body: JSON.stringify(body) });
    }

    this.events.emit("requesting", { url, body, option });

    return await fetch(url, option)
      .then(this.responseHandler)
      .catch((error) => {
        this.events.emit("failure", error);

        if (typeof error.validations !== "undefined") {
          throw error.validations;
        }

        if (error?.status === 401) {
          this.events.emit("unauthorized", error);
        }

        throw error;
      });
  }

  responseHandler(response) {
    if (response.status === 401) {
      throw response;
    }

    return response.json().then((payload) => {
      if (typeof payload.error !== "undefined") {
        const error = {
          validations: payload.error,
          status: response.status,
          message: payload.message,
        };

        throw error;
      }

      //events.emit("success", payload, response);

      if (typeof payload.total !== "undefined") {
        return payload;
      }

      return payload;
    });
  }

  GET(path, query) {
    return this.request(path, "GET", query, true);
  }

  POST(path, body) {
    return this.request(path, "POST", body);
  }

  PUT(path, body) {
    return this.request(path, "PUT", body);
  }

  DELETE(path, query) {
    return this.request(path, "DELETE", query, true);
  }
}

export const Rest = new restLibrary();

if (process.env.NODE_ENV === "development") {
  Rest.events.on("requesting", (payload) => {
    console.group("REST: @requesting");
    console.log(`URI: ${payload.url}`);
    console.log(`METHOD: ${payload.option.method}`);

    if (payload.body) {
      console.groupCollapsed("PARAMS");
      console.log(payload.body);
      console.groupEnd();
    }

    console.groupCollapsed("HEADERS");
    console.table(payload.option.headers);
    console.groupEnd();

    console.groupEnd();
  });

  Rest.events.on("success", (payload, response) => {
    Logger.info("@success", response.url, response.status, payload);
  });

  Rest.events.on("failure", (errors) => {
    Logger.info("@failure", errors);
  });
}
