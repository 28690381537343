/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import _ from "underscore";
import cn from "classnames";

import { Select } from "../../../services/action";

const Remote = ({
  name,
  url,
  initialValue,
  row,
  params,
  onSelected,
  placeholder,
  errors,
  label,
  required,
  keys = null,
  disabled = false,
  isMulti,
  isSearch = false,
  hint,
  horizontal,
  onClear,
  UseOnClear = false,
}) => {
  const Dispatch = useDispatch();
  const [value, setValue] = React.useState(null);

  const loadOptions = async (inputValue, callback) => {
    const p = {
      ...params,
      search: inputValue ? inputValue : params?.search ? params?.search : "",
      page: params?.page || 1,
      limit: params?.limit || 100,
    };

    if (isSearch === true) {
      if (inputValue) {
        const resp = await Dispatch(Select.$get(url, p));

        let needValue = false;
        setValue(null);

        if (
          typeof initialValue !== "undefined" &&
          typeof initialValue !== "object" &&
          initialValue !== null
        ) {
          needValue = true;
        }

        let result = [];
        if (resp?.message === "success") {
          result = _.map(resp?.data, function (d) {
            const no = { ...row(d), original: d };

            if (needValue) {
              if (d?.id === initialValue) {
                setValue(no);
              } else if (d?.village_id === initialValue) {
                setValue(no);
              } else if (d?.regency_id === initialValue) {
                setValue(no);
              }
            }
            return no;
          });
        }
        return result;
      }
    } else {
      const resp = await Dispatch(Select.$get(url, p));

      let needValue = false;
      setValue(null);

      if (
        typeof initialValue !== "undefined" &&
        typeof initialValue !== "object" &&
        initialValue !== null
      ) {
        needValue = true;
      }

      let result = [];
      if (resp?.message === "success") {
        result = _.map(resp?.data, function (d) {
          const no = { ...row(d), original: d };

          if (needValue) {
            if (d?.id === initialValue) {
              setValue(no);
            } else if (d?.village_id === initialValue) {
              setValue(no);
            } else if (d?.regency_id === initialValue) {
              setValue(no);
            } else if (d?.province_id === initialValue) {
              setValue(no);
            }
          }
          return no;
        });
      }
      return result;
    }
  };

  const handleInputChange = (newValue, { action }) => {
    setValue(newValue);

    if (isMulti) {
      onSelected({ ...value, ...(newValue || null) });
    } else {
      onSelected(newValue?.original || null);
    }

    if (UseOnClear) {
      if (action === "clear") {
        onClear();
      }
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [keys]);

  return (
    <div className={horizontal ? "row" : ""}>
      {label ? (
        <label
          className={cn(
            "form-label fw-light fs-6",
            required ? "required" : "",
            horizontal ? "col-3 col-form-label" : ""
          )}
        >
          {label}
        </label>
      ) : null}
      <div className={horizontal ? "col" : null}>
        <AsyncSelect
          name={name}
          classNamePrefix={errors ? "is-invalid" : "rounded-3 select "}
          cacheOptions
          placeholder={placeholder}
          value={value}
          loadOptions={loadOptions}
          defaultOptions
          onChange={handleInputChange}
          isClearable
          isSearchable
          className="bg-white"
          isDisabled={disabled}
          key={keys}
          isMulti={isMulti}
        />
        {hint ? <small className="form-hint pt-1">{hint}</small> : null}
        {errors ? (
          <div className="invalid-feedback d-block">{errors}</div>
        ) : null}
      </div>
    </div>
  );
};

export default Remote;
