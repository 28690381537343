import React from "react";
import { connect } from "react-redux";

import Screen from "./router";

const App = ({ appReady }) => {
  return <>{appReady && <Screen />}</>;
};

const mapStateToProps = (state) => {
  return {
    appReady: state.App.isReady,
  };
};

export default connect(mapStateToProps)(App);
