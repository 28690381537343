import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FiAlertTriangle } from "react-icons/fi";

export const useModal = (type) => {
  const [isShow, setIsShow] = React.useState(false);

  const $handleClose = () => {
    setIsShow(false);
  };

  const Render = ({ title, footer, children, className, fullscreen }) => {
    return (
      <Modal
        show={isShow}
        onHide={$handleClose}
        backdrop="static"
        centered
        className={className}
        fullscreen={fullscreen}
        size="small"
      >
        {type !== "dialog" ? (
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        ) : null}
        <Modal.Body>{children}</Modal.Body>
        {footer ? <Modal.Footer>{footer}</Modal.Footer> : null}
      </Modal>
    );
  };

  const Dialog = ({ title, onClick, children, variant }) => {
    return (
      <Modal
        show={isShow}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose}></div>
          {variant === "confirm" ? (
            <>
              <div className="modal-status bg-primary"></div>
            </>
          ) : (
            <>
              <div className="modal-status bg-danger"></div>
              <FiAlertTriangle className="mb-2 text-danger" size={90} />
              <h3>Are you sure?</h3>
            </>
          )}
          {children}
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100">
            <div className="row">
              <div className="col">
                <Button
                  variant="white"
                  className="w-100"
                  onClick={$handleClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="col">
                {variant === "confirm" ? (
                  <Button variant="primary" className="w-100" onClick={onClick}>
                    {title}
                  </Button>
                ) : (
                  <Button variant="danger" className="w-100" onClick={onClick}>
                    {title}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  return {
    show(): void {
      setIsShow(true);
    },
    hide(): void {
      setIsShow(false);
    },
    Render,
    Dialog,
  };
};
