import React from "react";
import Header from "./header";
import Body from "./body";
import Footer from "./footer";

const Layout = ({ children }) => {
  return <div className="wrapper">{children}</div>;
};

Layout.Header = Header;
Layout.Body = Body;
Layout.Footer = Footer;
export default Layout;
