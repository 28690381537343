import React from "react";
import heroImg from "../../../assets/hormat.png";

export const AboutSection = () => {
  return (
    <section className="about ">
      <div className="container pt-3 pt-md-5 pb-md-0 pb-3">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="text-start">
              <h1 className="fs-2 text-dark fw-bold">Envio Logistics</h1>

              <div className="fs-6 text-dark fw-light mt-3">
                Envio digital logistics didirikan pada tahun 2021 dengan
                mengusung revolusi industri pada bidang logistik dengan
                menyediakan sebuah platform digital yang terintegrasi dan dapat
                mengelola kebutuhan logistik sebuah perusahaan secara end to
                end.
              </div>
              <div className="fs-6 text-dark fw-light mt-3">
                Dibantu dengan system yang terintegrasi tersebut, envio dapat
                membantu mendongkrak kegiatan bisnis model seperti B2B, B2C, SME
                dengan jasa dedicated solution dimana envio menghandle seluruh
                kegiatan logistik dari sebuah perusahaan dan membuat system
                pengeluaran berubah dari fix dan overhead cost menjadi variable
                cost dimana hal tersebut bertujuan menjaga neraca pengeluaran
                agar tetap stabil.
              </div>
              <div className="fs-6 text-dark fw-light mt-3">
                Tidak berhenti disitu, Envio dapat menyesuaikan jasa dengan
                kebutuhan sebuah perusahaan (customize service) dimana
                perusahaan dapat memilih apa saja yg mau digunakan pada servis
                envio. Saat ini Envio memiliki 7328 truk, 40 gudang dan 713
                kurir yang selalu sedia untuk memenuhi kebutuhan perusahaan
                Anda.
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 d-none d-md-block">
            <div className="position-relative w-100 h-100 mt-4">
              <div className="position-absolute end-0" style={{ bottom: -118 }}>
                <img
                  src={heroImg}
                  alt=""
                  style={{ maxHeight: 600 }}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
