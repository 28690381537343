import React from "react";

const MainBar = ({ children }) => {
  return (
    <div className={"page-body"}>
      <div className={"container"}>{children}</div>
    </div>
  );
};
export default MainBar;
