import AboutScreen from ".";

const routes = [
  {
    path: "/about",
    component: AboutScreen,
  },
];

export default routes;
