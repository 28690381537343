import React from "react";

import tech from "../../../assets/tech-asia.png";
import gizmologi from "../../../assets/gizmologi.png";
import detik from "../../../assets/detik.png";
import daily from "../../../assets/daily-social.png";
import akurat from "../../../assets/akurat.png";
import info from "../../../assets/info-komputer.png";
import kabar from "../../../assets/kabar-gadget.png";

export const ReportSection = () => {
  return (
    <section>
      <div className="container py-3 mb-3 mb-md-0 py-md-5">
        <div className="text-start">
          <h1 className="fs-2 text-dark fw-bold">Diliput oleh</h1>
        </div>

        <div className="d-flex justify-content-center gap-3 gap-md-5 align-items-center mt-3 mt-md-5">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={tech}
              alt=""
              style={{ maxHeight: 50 }}
              className="img-fluid cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.techinasia.com/envio-pre-seed-funding",
                  "_blank"
                )
              }
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={gizmologi}
              alt=""
              style={{ maxHeight: 50 }}
              className="img-fluid cursor-pointer"
              onClick={() =>
                window.open(
                  "https://gizmologi.id/news/startup/pendanaan-envio-akselerasi-ekosistem-logistik/",
                  "_blank"
                )
              }
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={detik}
              alt=""
              style={{ maxHeight: 50 }}
              className="img-fluid cursor-pointer"
              onClick={() =>
                window.open(
                  "https://inet.detik.com/business/d-5988296/startup-logistik-b2b-envio-dapat-suntikan-dana-dari-antler-iterative",
                  "_blank"
                )
              }
            />
          </div>
        </div>

        <div className="d-flex  justify-content-center gap-3 gap-md-5 align-items-center mt-3 mt-md-5">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={daily}
              alt=""
              style={{ maxHeight: 50 }}
              className="img-fluid cursor-pointer"
              onClick={() =>
                window.open(
                  "https://dailysocial.id/post/pendanaan-awal-envio-logistik",
                  "_blank"
                )
              }
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={info}
              alt=""
              style={{ maxHeight: 50 }}
              className="img-fluid cursor-pointer"
              onClick={() =>
                window.open(
                  "https://infokomputer.grid.id/read/123190716/startup-di-bidang-logistik-envio-berhasil-raih-pendanaan-pre-seed?page=all",
                  "_blank"
                )
              }
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={kabar}
              alt=""
              style={{ maxHeight: 50 }}
              className="img-fluid cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.kabargadget.com/digital-logistics-b2b-startup-envio-menerima-pendanaan-pre-seed/",
                  "_blank"
                )
              }
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={akurat}
              alt=""
              style={{ maxHeight: 50 }}
              className="img-fluid cursor-pointer"
              onClick={() =>
                window.open(
                  "https://akurat.co/startup-b2b-logistik-digital-envio-raih-pendanaan-pre-seed",
                  "_blank"
                )
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};
