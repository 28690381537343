import * as Bs from "react-icons/bs";
import { formatDate } from "utilities/common";
import logo from "ui/assets/logo.png";
import dark from "ui/assets/logo-dark.png";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  if (splitLocation[1] === "distribution")
    return (
      <footer className="footer border-0 bg-white ">
        <div className="container d-flex flex-column justify-content-center align-items-center">
          <img src={dark} alt="" />
          <div className="mt-3 fs-8 ">
            Copyright © 2024 Envio Logistics | All Rights Reserved{" "}
          </div>
        </div>
      </footer>
    );

  return (
    <footer className="footer bg-secondary d-print-none text-white py-5">
      <div className="container ">
        <div className="row">
          <div className="col-md-6 col-12">
            <img src={logo} alt="" className="navbar-brand-image" />

            <div className="pt-3 pt-md-4">
              <p className="mb-0 fw-bold fs-6">Address</p>
              <p className="fs-7">
                Jl. Puri Kembangan No.57F, RT.11/RW.5,
                <br /> Kembangan Selatan, Kec. Kembangan, Kota Jakarta Barat.
                <br /> Daerah Khusus Ibukota Jakarta 11610
              </p>
            </div>

            <div className="pt-0 pt-md-4 d-none d-md-block">
              <p className="mb-3 fs-7 mt-3 mt-md-0 fw-light">
                Copyright @ {formatDate(Date.now(), "YYYY")} envio.co.id <br />{" "}
                All rights reserved.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="pt-2 pt-md-0">
              <p className="mb-0 fw-bold fs-6">Email</p>
              <p className="fs-7 mb-0 mb-md-2 pb-md-1">support@envio.co.id</p>
            </div>
            <div className="pt-3 pt-md-4">
              <p className="mb-0 fw-bold fs-6">Work Hour</p>
              <p className="fs-7">Monday - Friday, 09.00 - 21.00 GMT +7</p>
            </div>

            <div className="pt-3 pt-md-4">
              <div className="d-flex gap-3">
                <Bs.BsWhatsapp
                  size={30}
                  className="cursor-pointer"
                  onClick={() =>
                    window.open("https://wa.me/6285163085591", "_blank")
                  }
                />
                <Bs.BsInstagram
                  size={30}
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/envio.asia/",
                      "_blank"
                    )
                  }
                />
                <Bs.BsLinkedin
                  size={30}
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/envio-id",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>

            <div className="pt-3 pt-md-4 d-block d-md-none">
              <p className="mb-3 fs-7 mt-3 mt-md-0 fw-light">
                Copyright @ {formatDate(Date.now(), "YYYY")} envio.co.id <br />{" "}
                All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
