import React from "react";
import cdd from "../../../assets/cdd.png";
import b from "../../../assets/b.png";
import bv from "../../../assets/bv.png";
import cde from "../../../assets/cde.png";
import f from "../../../assets/f.png";
import r from "../../../assets/r.png";
import t from "../../../assets/t.png";
import tr from "../../../assets/tr.png";
import tw from "../../../assets/tw.png";

import container from "../../../assets/container.png";
import sea from "../../../assets/sea.png";
import air from "../../../assets/air.png";

import xtr from "../../../assets/xtr.png";
import xpr from "../../../assets/xpr.png";

import vas from "../../../assets/vas.png";

export const TipeSection = () => {
  return (
    <section className="bg-white">
      <div className="container">
        <div className=" pt-3 pt-md-5">
          <div className="text-start">
            <h1 className="fs-2 text-primary fw-bold">Tipe Pengiriman</h1>
          </div>
        </div>

        <div className="row align-items-center py-3 py-md-5">
          <div className="col-md-6 mb-3 mb-md-0 mt-3 mt-md-0 ">
            <div>
              <h2 className="fs-3 fw-bold">Full Truck Load (FTL)</h2>
              <div className="fs-5 text-dark fw-light mt-3 mt-md-4">
                Pengiriman barang dengan menggunakan truk, dimana barang
                tersebut merupakan milik dari satu perusahaan atau perorangan.
                Muatan di dalam truk adalah barang eksklusif dari penyewa atau
                perusahaan yang menggunakan jasa FTL
              </div>
            </div>

            <div className="row align-items-center justify-content-center  mt-3 mt-md-5">
              <div className="col-4 text-center mb-4">
                <img
                  src={bv}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Van/Pickup</div>
              </div>

              <div className="col-4 text-center mb-4">
                <img
                  src={cde}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Cold Diesel Engkel</div>
              </div>

              <div className="col-4 text-center mb-4">
                <img
                  src={cdd}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Cold Diesel Double</div>
              </div>

              <div className=" col-4 text-center">
                <img
                  src={f}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Fuso</div>
              </div>

              <div className=" col-4 text-center">
                <img
                  src={tr}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Tronton</div>
              </div>

              <div className=" col-4 text-center">
                <img
                  src={t}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Trailer</div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="bg-dark rounded-3 text-center py-4 px-3 text-white">
              <div>
                <img
                  src={cdd}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 300 }}
                />
                <div className="text-white fs-3 fw-bold">Bak</div>
                <span className="text-white fw-light">Colt Diesel Double</span>
              </div>

              <div className="d-flex align-items-center justify-content-center gap-md-4 gap-1 mt-3 mt-md-4">
                <div className="text-center">
                  <img
                    src={cdd}
                    alt=""
                    className="img-fluid"
                    style={{ maxHeight: 50 }}
                  />
                  <div className="text-white fw-light">Bak</div>
                </div>

                <div className="text-center">
                  <img
                    src={b}
                    alt=""
                    className="img-fluid"
                    style={{ maxHeight: 50 }}
                  />
                  <div className="text-white fw-light">Box</div>
                </div>

                <div className="text-center">
                  <img
                    src={tw}
                    alt=""
                    className="img-fluid"
                    style={{ maxHeight: 50 }}
                  />
                  <div className="text-white fw-light">Three Way</div>
                </div>

                <div className="text-center">
                  <img
                    src={r}
                    alt=""
                    className="img-fluid"
                    style={{ maxHeight: 50 }}
                  />
                  <div className="text-white fw-light">Refer</div>
                </div>
              </div>

              <div className="mt-3 mt-md-4 fw-bold fs-5">
                <div>Max: 5000 Kg</div>
                <div>
                  P: 4,5 m &nbsp;&nbsp;&nbsp;&nbsp; L: 2 m
                  &nbsp;&nbsp;&nbsp;&nbsp; T: 2 m
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center gap-md-4 gap-1 mt-3 mt-md-4">
                <div className="bg-primary-lt rounded-pill px-3 fw-semibold fs-8 py-1">
                  Trip Based
                </div>
                <div className="bg-primary-lt rounded-pill px-3 fw-semibold fs-8 py-1">
                  Semi Dedicated
                </div>
                <div className="bg-primary-lt rounded-pill px-3 fw-semibold fs-8 py-1">
                  Dedicated Fleet
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center py-3 py-md-5">
          <div className="col-md-6 order-2 order-md-1">
            <div className="bg-dark rounded-3 text-center py-4 px-3 text-white">
              <div>
                <img
                  src={container}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 300 }}
                />
                <div className="text-white fs-3 fw-bold">
                  Hige Cube Container
                </div>
                <span className="text-white fw-light">Seafreight</span>
              </div>

              <div className="d-flex align-items-center justify-content-center gap-md-3 gap-2 mt-3 mt-md-4">
                <div className="text-primary fs-2 fw-bold">40"</div>
                <div className=" fw-bold fs-5 text-start">
                  <div>Max: 5000 Kg</div>
                  <div>
                    P: 4,5 m &nbsp;&nbsp;&nbsp;&nbsp; L: 2 m
                    &nbsp;&nbsp;&nbsp;&nbsp; T: 2 m
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-center mt-3 mt-md-4 ">
                <div className="col-6 ">
                  <div className="bg-primary rounded-pill px-3 fw-semibold fs-8 py-1 mb-3">
                    Non Containerized
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="bg-primary rounded-pill px-3 fw-semibold fs-8 py-1 mb-3">
                    Container HC
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="bg-primary rounded-pill px-3 fw-semibold fs-8 py-1 mb-3">
                    Container GP
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="bg-primary rounded-pill px-3 fw-semibold fs-8 py-1 mb-3">
                    Container RF
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center gap-md-4 gap-1 mt-3 mt-md-4">
                <div className="bg-primary-lt rounded-pill px-3 fw-semibold fs-8 py-1">
                  Weight/Volume Base
                </div>
                <div className="bg-primary-lt rounded-pill px-3 fw-semibold fs-8 py-1">
                  Trip Base
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-3 mb-md-0 mt-3 mt-md-0 order-1 order-md-2">
            <div className="text-start text-md-end">
              <h2 className="fs-3 fw-bold">Line Haul</h2>
              <div className="fs-5 text-dark fw-light mt-3 mt-md-4">
                Line Haul dalam ekspedisi memiliki arti metode pengiriman barang
                atau kargo antara kota. Terdiri dari Udara, Darat dan Laut.
              </div>
            </div>

            <div className="row align-items-center justify-content-center  mt-3 mt-md-5">
              <div className="col-6 text-center mb-4">
                <img
                  src={sea}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Seafreight</div>
              </div>

              <div className="col-6 text-center mb-4">
                <img
                  src={air}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Airfreight</div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center py-3 py-md-5">
          <div className="col-md-6 mb-3 mb-md-0 mt-3 mt-md-0 ">
            <div>
              <h2 className="fs-3 fw-bold">
                Less Than Truck Load (LTL) / Last Mile
              </h2>
              <div className="fs-5 text-dark fw-light mt-3 mt-md-4">
                Pengiriman barang yang menggabungkan pengiriman dari banyak
                pelanggan. Beberapa kelebihannya yakni antara lain pengiriman
                dengan menggunakan sistem ini lebih murah saat permintaan
                konsumen untuk produk yang diinginkan rata-rata stabil.
              </div>
            </div>

            <div className="row align-items-center justify-content-center  mt-3 mt-md-5">
              <div className="col-6 text-center mb-4">
                <img
                  src={xpr}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Cargo Xpress</div>
              </div>

              <div className="col-6 text-center mb-4">
                <img
                  src={xtr}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 60 }}
                />
                <div className="text-dark fw-semibold">Cargo Xtra</div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="bg-dark rounded-3 text-center py-4 px-3 text-white">
              <div>
                <img
                  src={xtr}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 300 }}
                />
                <div className="text-white fs-3 fw-bold">Cargo Xtra</div>
                <span className="text-white fw-light">
                  Salah satu layanan tercepat kami dalam perihal waktu
                  pengiriman.
                </span>
              </div>

              <div className="d-flex align-items-center justify-content-center gap-md-4 gap-1 mt-3 mt-md-4">
                <div className="bg-primary-lt rounded-pill px-3 fw-semibold fs-8 py-1">
                  Weight/Volume Base
                </div>
                <div className="bg-primary-lt rounded-pill px-3 fw-semibold fs-8 py-1">
                  Flat Rate
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center py-3 py-md-5">
          <div className="col-md-6 order-2 order-md-1">
            <div className="bg-dark rounded-3 text-center py-4 px-3 text-white">
              <div>
                <img
                  src={vas}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: 300 }}
                />
                <div className="text-white fs-3 fw-bold">Bulk Fulfillment</div>
                <span className="text-white fw-light">
                  Fulfillment yang digunakan khusus untuk pengiriman dalam
                  jumlah besar dikarenakan barang akan diambil per pallet
                </span>
              </div>

              <div className="d-flex gap-md-4 gap-2 align-items-center justify-content-center mt-3 mt-md-4 ">
                <div className="bg-primary rounded-pill px-3 px-md-4 fw-semibold fs-8 py-1 mb-3">
                  Bulk
                </div>
                <div className="bg-primary rounded-pill px-3 px-md-4 fw-semibold fs-8 py-1 mb-3">
                  Retail
                </div>
                <div className="bg-primary rounded-pill px-3 px-md-4 fw-semibold fs-8 py-1 mb-3">
                  Cold Chain
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center gap-md-4 gap-1 mt-3 mt-md-4">
                <div className="bg-primary-lt rounded-pill px-3 fw-semibold fs-8 py-1">
                  Weight/Volume Base
                </div>
                <div className="bg-primary-lt rounded-pill px-3 fw-semibold fs-8 py-1">
                  Trip Base
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-3 mb-md-0 mt-3 mt-md-0 order-1 order-md-2">
            <div className="text-start text-md-end">
              <h2 className="fs-3 fw-bold">Fulfillment/Transit/VAS</h2>
              <div className="fs-5 text-dark fw-light mt-3 mt-md-4">
                Jasa penyewaan Tempat (berdasarkan kubik) , jasa inbound ,
                outbound , perhitungan stock , dan laporan secara keseluruhan
                terhadap penyewa.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
