/**
 * All services state definitions
 */

import * as Activity from "./activity/state";
import * as App from "./app/state";
import * as Form from "./form/state";

const $state = {
  Activity,
  App,
  Form,
};

export default $state;
