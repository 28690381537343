import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

import wholesale from "../../../assets/wholesale.png";
import cold from "../../../assets/cold.png";
import fresh from "../../../assets/fresh.png";
import intracity from "../../../assets/intracity.png";

export const SolutionSection = () => {
  const History = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <section>
      <div className="container py-3 py-md-5">
        <div className="d-flex align-items-center justify-content-center">
          <div className="col-md-8 text-center">
            <h1 className="fs-2 text-dark fw-bold">
              Beragam Solusi Logistik untuk Usaha Anda
            </h1>
          </div>
        </div>

        <div className="categories mt-3 mt-md-5 px-md-4 px-3">
          <Slider {...settings}>
            <div className="d-flex flex-column position-relative  align-items-center justify-content-center ps-2 categories-container">
              <div className=" d-none d-md-block">
                <img src={wholesale} alt="img-fluid" style={{ height: 500 }} />
              </div>

              <div className="d-flex position-absolute solution">
                <div className="col-md-6 p-1 p-md-4">
                  <h2 className="text-bold fs-3 pt-0 pt-md-3">
                    Wholesale Distribution
                  </h2>
                  <div className="fs-6">
                    Penanganan Distribusi Standar untuk produk General Cargo
                    atau Makanan Kering melibatkan pemindahan produk dalam
                    jumlah besar dari gudang ke gudang atau pelanggan b2b
                    biasanya dalam bentuk penuh (FTL) atau kurang dari muatan
                    truk (LTL).
                  </div>

                  <div className="mt-3">
                    <button
                      className="btn btn-primary px-4"
                      onClick={() => History("/services/wholesale")}
                    >
                      Pelajari Lebih Lanjut
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column position-relative  align-items-center justify-content-center ps-2 categories-container">
              <div className=" d-none d-md-block">
                <img src={intracity} alt="img-fluid" style={{ height: 500 }} />
              </div>
              <div className="d-flex position-absolute solution">
                <div className="col-md-6 p-1 p-md-4">
                  <h2 className="text-bold fs-3 pt-0 pt-md-3">
                    Intracity/Intercity
                  </h2>
                  <div className="fs-6">
                    Penanganan distribusi lebih hemat dikarenakan Envio memiliki
                    ritase minimal 1x dalam sehari dan Envio memiliki gudang
                    yang tersebar di beberapa kota di Indonesia untuk menampung
                    pengiriman serta mengurus pengantaran ke penerima barang.
                  </div>

                  {/* <div className="mt-3">
                    <button className="btn btn-primary px-4">
                      Pelajari Lebih Lanjut
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column position-relative  align-items-center justify-content-center ps-2 categories-container">
              <div className=" d-none d-md-block">
                <img src={fresh} alt="img-fluid" style={{ height: 500 }} />
              </div>
              <div className="d-flex position-absolute solution">
                <div className="col-md-6 p-1 p-md-4 ">
                  <h2 className="text-bold fs-3 pt-0 pt-md-3">Fresh Produce</h2>
                  <div className="fs-6">
                    Penanganan distribusi dengan fasilitas temperatur yang
                    terkontrol (temperature-controlled), mulai dari proses
                    persiapan pengiriman, proses pengemasan barang perishable
                    sampai dengan kedatangan di tujuan.
                  </div>

                  {/* <div className="mt-3">
                    <button className="btn btn-primary px-4">
                      Pelajari Lebih Lanjut
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column position-relative  align-items-center justify-content-center ps-2 categories-container">
              <div className=" d-none d-md-block">
                <img src={cold} alt="img-fluid" style={{ height: 500 }} />
              </div>
              <div className="d-flex position-absolute solution">
                <div className="col-md-6 p-1 p-md-4">
                  <h2 className="text-bold fs-3 pt-0 pt-md-3">Cold Chain</h2>
                  <div className="fs-6">
                    Penanganan distribusi Frozen goods/foods/barang yang
                    membutuhkan suhu dingin ke dalam dan luar kota dilengkapi
                    berbagai fasilitas pendingin pada proses pengiriman.
                  </div>

                  {/* <div className="mt-3">
                    <button className="btn btn-primary px-4">
                      Pelajari Lebih Lanjut
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};
