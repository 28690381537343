import MenuSm from "./menu.sm";
import MenuConsended from "./menu.consended";

const Menu = () => {
  return;
};

Menu.Consended = MenuConsended;
Menu.Mobile = MenuSm;

export default Menu;
