import HomeScreen from ".";

const routes = [
  {
    path: "/",
    component: HomeScreen,
  },
];

export default routes;
