import React from "react";
import intro from "../../../assets/introduction.mp4";

export const FullscreenVideo = () => {
  return (
    <section
      className="position-relative d-flex align-items-center justify-content-center"
      style={{ minHeight: "calc(100vh)", marginTop: -80 }}
    >
      <div className="video-overlay"></div>
      <video autoPlay muted loop id="intro-video">
        <source src={intro} type="video/mp4" />
      </video>

      <div className="container row mt-0 mt-md-5">
        <div className="col-md-6 col-12 text-overlay text-center text-md-start">
          <div className="home-hero-text">
            <h1 className="fs-1 text-white title-hero">
              Logistics Enabler Berbasis Teknologi Pertama di Indonesia
            </h1>
            <div className="fs-5 text-white fw-light mt-4">
              Perusahaan Logistik Digital Pertama di Indonesia berbasis
              teknologi yang di dukung oleh jaringan distribusi Se-Nusantara.
              Membawa Industri logistik tanah air menuju era transformasi
              digital.
            </div>

            {/* <div className="btn btn-primary mt-4 fs-6 px-4">Send Enquiry</div> */}
          </div>
        </div>
      </div>
    </section>
  );
};
