import * as Action from "./action";

const defineInitialState = () => ({
  errors: {},
  success: false,
  loading: false,
  message: "",
});

export function reducer(state = defineInitialState(), action) {
  switch (action.type) {
    case Action.$failure.ACTION:
      return {
        errors: action.errors,
        success: false,
        message: action.message,
        loading: false,
      };
    case Action.$success.ACTION:
      return {
        errors: {},
        success: true,
        message: action.message,
        loading: false,
      };
    case Action.$requesting.ACTION:
      return {
        errors: {},
        success: false,
        message: "",
        loading: true,
      };
    case Action.$reset.ACTION:
      return {
        errors: {},
        success: false,
        message: "",
        loading: false,
      };
    default:
      return state;
  }
}
