import * as Dispatcher from './action';

const defineInitialState = () => ({
  services: {
    default: false,
  },
  processing: false,
});

export function reducer(state = defineInitialState(), action) {
  switch (action.type) {
    case Dispatcher.$reset.ACTION:
      return defineInitialState();
    case Dispatcher.$processing.ACTION: {
      const services = {
        ...state.services,
        [action.operation]: true,
      };
      return {
        ...state,
        services,
        processing: Object.values(services).reduce((acc, v) => acc || v, false),
      };
    }
    case Dispatcher.$done.ACTION: {
      const services = {
        ...state.services,
        [action.operation]: false,
      };
      return {
        ...state,
        services,
        processing: Object.values(services).reduce((acc, v) => acc || v, false),
      };
    }
    default:
      return state;
  }
}
