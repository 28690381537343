import React from "react";

export const SkemaSection = () => {
  return (
    <section className="bg-light">
      <div className="container">
        <div className=" pt-3 pt-md-5">
          <div className="text-start">
            <h1 className="fs-2 text-primary fw-bold">Informasi Skema</h1>
          </div>
        </div>

        <div className="row  py-3 py-md-5">
          <div className="col-12 col-md-6 mb-4 mb-md-5">
            <h2 className="fs-3 fw-bold">Weight Based</h2>
            <div className="fs-6 text-dark fw-light mt-3 mt-md-4">
              Pengiriman barang dengan menggunakan truk, dimana barang tersebut
              merupakan milik dari satu perusahaan atau perorangan. Muatan di
              dalam truk adalah barang eksklusif dari penyewa atau perusahaan
              yang menggunakan jasa FTL
            </div>
          </div>

          <div className="col-12 col-md-6 mb-4 mb-md-5">
            <h2 className="fs-3 fw-bold">Flat Rate/Per Point Based</h2>
            <div className="fs-6 text-dark fw-light mt-3 mt-md-4">
              Kapasitas Pengiriman Dikenakan tarif tetap, per titik drop atau
              multi drop per pesanan pengiriman
            </div>
          </div>

          <div className="col-12 col-md-6 mb-4 mb-md-5">
            <h2 className="fs-3 fw-bold">Trip Based</h2>
            <div className="fs-6 text-dark fw-light mt-3 mt-md-4">
              Kapasitas Unit dijamin untuk pedagang tertentu untuk perjalanan
              atau reitase tertentu yang menawarkan fleksibilitas, baik untuk
              permintaan sesekali atau antar kota
            </div>
          </div>

          <div className="col-12 col-md-6 mb-4 mb-md-5">
            <h2 className="fs-3 fw-bold">Semi-Dedicated Fleet</h2>
            <div className="fs-6 text-dark fw-light mt-3 mt-md-4">
              Kapasitas Unit dijamin untuk pedagang tertentu untuk slot waktu
              tertentu dalam sehari, biasanya kontrak per bulan. Termasuk Biaya
              Pengemudi &amp; Pengoperasian.
            </div>
          </div>

          <div className="col-12 col-md-6 mb-4 mb-md-5">
            <h2 className="fs-3 fw-bold">Dedicated Fleet</h2>
            <div className="fs-6 text-dark fw-light mt-3 mt-md-4">
              Kapasitas Unit dijamin untuk pedagang tertentu untuk jangka waktu
              lanjutan biasanya kontrak per bulan. Termasuk Biaya Pengemudi
              &amp; Pengoperasian.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
