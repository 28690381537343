import React from "react";
import Menu from "../menu";
import logo from "ui/assets/logo.png";
import white from "ui/assets/envio-white.png";
import cn from "classnames";
import { useNavigate, useLocation } from "react-router";

const Header = ({ consended }) => {
  const History = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const [navbar, setNavbar] = React.useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  React.useEffect(() => {
    if (splitLocation[1] === "" || splitLocation[1] === "distribution") {
      changeBackground();
      // adding the event when scroll change background
      window.addEventListener("scroll", changeBackground);
    } else {
      setNavbar(true);
    }
  }, [splitLocation]);

  if (splitLocation[1] === "distribution")
    return (
      <header
        className={cn(
          "navbar navbar-expand-md navbar-dark sticky-top d-print-none py-3",
          navbar ? "bg-dark" : ""
        )}
      >
        <div className="container">
          <h1
            onClick={() => History("/")}
            className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal "
            style={{ cursor: "pointer" }}
          >
            <img src={white} alt="" className="navbar-brand-image" />
          </h1>
        </div>
      </header>
    );

  return (
    <header
      className={cn(
        "navbar navbar-expand-md navbar-dark sticky-top d-print-none py-3",
        navbar ? "bg-dark" : ""
      )}
    >
      <div className="container  ">
        <h1
          onClick={() => History("/")}
          className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal "
          style={{ cursor: "pointer" }}
        >
          <img src={logo} alt="" className="navbar-brand-image" />
        </h1>
        <Menu.Mobile />

        {consended ? <Menu.Consended /> : null}
      </div>
    </header>
  );
};

export default Header;
