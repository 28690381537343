import * as ActionCreator from "../../utilities/action.creator";
import { $processing, $done } from "../activity/action";
import { Rest } from "../../utilities/rest";

const MODULE = "Select";

const API_URL = `https://api.envio.co.id/v1`;

export const $get = ActionCreator.Async(MODULE, "Get", (url, params = {}) => {
  return async (dispatch) => {
    dispatch($processing($get.OPERATION));

    return await Rest.GET(`${API_URL}${url}`, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => {
        dispatch($done($get.OPERATION));
      });
  };
});
