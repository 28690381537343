import ServiceScreen from ".";
import WholesaleScreen from "./wholesale";

const routes = [
  {
    path: "/services",
    component: ServiceScreen,
  },
  {
    path: "/services/wholesale",
    component: WholesaleScreen,
  },
];

export default routes;
