import PrivacyScreen from ".";
import TermsScreen from "./terms";

const routes = [
  {
    path: "/app-privacy",
    component: PrivacyScreen,
  },
  {
    path: "/terms-of-service",
    component: TermsScreen,
  },
];

export default routes;
