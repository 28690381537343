/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import undraw from "../../assets/undraw.png";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DriverDeeplinkScreen = () => {
  let query = useQuery();
  const History = useNavigate();
  const [isButtonEnabled, setIsButtonEnabled] = React.useState(false);
  const [link, setLink] = React.useState("");

  React.useEffect(() => {
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (query.get("ref_code")) {
      let ref = query.get("ref_code");

      if (isAndroid) {
        setLink(
          `intent://Signin?ref_code=${ref}#Intent;scheme=envio-drivers;package=com.envio.drivers;end`
        );

        window.location = `intent://Signin?ref_code=${ref}#Intent;scheme=envio-drivers;package=com.envio.drivers;end`;
      } else {
        setLink(`https://driver.envio.co.id/signin?ref_code=${ref}`);

        window.location = `https://driver.envio.co.id/signin?ref_code=${ref}`;
      }
    } else {
      History("/");
    }
  }, []);

  React.useEffect(() => {
    // Use a setTimeout to enable the button after 10 seconds
    const timeout = setTimeout(() => {
      setIsButtonEnabled(true);
    }, 10000); // 10000 milliseconds = 10 seconds

    return () => {
      // Clear the timeout when the component unmounts to avoid memory leaks
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      className="container py-5 px-1 px-md-4"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className=" py-3 px-3 "
        style={{
          width: 400,
          border: "1px solid #ddd",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={undraw}
          alt=""
          style={{ maxHeight: 200 }}
          className="img-fluid center mb-4"
        />
        <p className="fs-4 mb-1 fw-bold text-center">Hang Tight !</p>
        <p className="fs-6 mb-1 text-center ">
          You're being redirected to another page, it may takes upto 10 seconds
        </p>
        <p className="fs-6 mb-1 text-center ">
          or click the button below if you are not redirected
        </p>
        <button
          className="btn btn-outline-primary rounded-2 mt-3 px-3 py-2 border border-primary bg-primary-lt text-dark fw-medium text-capitalize "
          onClick={() => window.open(link)}
          disabled={!isButtonEnabled}
        >
          Open envio driver
        </button>
      </div>
    </div>
  );
};

export default DriverDeeplinkScreen;
