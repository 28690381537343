import DistributionScreen from ".";

const routes = [
  {
    path: "/distribution",
    component: DistributionScreen,
  },
];

export default routes;
