import React from "react";
import promo from "ui/assets/graph/promo.png";
import Slider from "react-slick";
import data from "./price.json";
import { currencyFormat } from "utilities/common";

const PriceSection = ({ priceRef }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    className: "price-carousel",
    autoplay: true,
  };

  return (
    <div className="container my-5" id="price" ref={priceRef}>
      <div className="services-container px-0">
        <div className="title text-white text-center mb-3 mb-md-3 font-bold">
          <img src={promo} alt="" className="img-title img-fluid" />
        </div>

        <Slider {...settings}>
          {data?.map((d, idx) => (
            <div key={idx} className="slide-wrapper px-1 px-md-3">
              <div className="border border-white border-2 rounded-3 pt-3">
                <div className="text-center text-white fs-4 mb-3">
                  Area Distribusi {d?.area}
                </div>
                <div className="table-responsive mb-0">
                  <table className="table table-vcenter" width="100%">
                    <thead className="bg-yellow">
                      <tr
                        className="th text-white text-uppercase text-center"
                        style={{ fontWeight: 700 }}
                      >
                        <td>Origin</td>
                        <td>Destination</td>
                        <td>Harga Normal</td>
                        <td>Harga Diskon</td>
                      </tr>
                    </thead>
                    <tbody>
                      {d?.price_list?.map((pl, id) => (
                        <tr key={id} className="tb text-white text-center fs-7">
                          <td>{pl?.origin}</td>
                          <td>{pl?.destination}</td>
                          <td>
                            <span className="strikethrough">
                              {currencyFormat(pl?.normal_price, "")}/Kg
                            </span>
                          </td>
                          <td>{currencyFormat(pl?.discount_price, "")}/Kg</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <div className="mt-4 mt-md-5 d-flex justify-content-center align-items-center">
          <div className="footnote-wrapper">
            <div className="border border-white border-2 rounded-3 p-3 mb-4">
              <div className="text-start text-white fs-5 mb-3">
                Syarat dan Ketentuan
              </div>

              <div className="text-white fs-7">
                <ul>
                  <li>
                    Tarif dibebankan berdasarkan volumetrik (P x L x T) /4.000
                  </li>
                  <li>Minimum order pesanan 10/Shipment</li>
                  <li>Minimum berat 10Kg/Pengiriman</li>
                  <li>
                    Pengembalian barang diterima saat pick up keesokan harinya
                  </li>
                  <li>Tarif diluar biaya asuransi</li>
                </ul>
              </div>
            </div>

            <div
              className="btn btn-block btn-white w-100 text-capitalize text-primary fw-bold rounded-3"
              onClick={() =>
                window.open("https://wa.me/6281213558273", "_blank")
              }
            >
              pesan sekarang
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceSection;
