import moment from "moment";

export const currencyFormat = (value, usingText = true) => {
  let txt = usingText ? "Rp " : "";

  return `${txt}${new Intl.NumberFormat("de-DE").format(value)}`;
};

export const formatDate = (v, format = "DD/MM/YYYY HH:mm") => {
  return moment(v).format(format);
};

export const isNewest = (v) => {
  return parseFloat(moment(v).format("x")) > Date.now() - 604800000;
};

// check if guest has referral code
// the referral code exists on 2 type url
// - /?utm_source=affiliate&referral_code=Koz6lPF
// - /partner/${ref_code}
export const getReferralCode = () => {
  const params = new URLSearchParams(window.location.href);
  let ref_code = params.get("referral_code");

  if (ref_code === "" || ref_code == null) {
    const paths = window.location.pathname.split("/");

    if (paths.length > 1 && paths[1] === "partner") {
      ref_code = `${paths[2]}halofudi`;
    }
  }

  return ref_code;
};

export const getQueryToken = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  let token = params["asset_type"];

  return typeof token === "undefined" ? null : token;
};

export const getQueryString = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  return Object.fromEntries(urlSearchParams.entries());
};
